<template>
    <div class="">
        <div v-show="currentUser.is_superuser || currentUser.is_ventura_admin" class="container" v-if="loading">
            <div class="fl-eq mt-4">
                <loading-animation/>
            </div>
        </div>

        <div v-show="currentUser.is_superuser || currentUser.is_ventura_admin" class="container" v-else-if="error">
            <div class="card bg-danger fl-te-c">
                <div>
                    <error-icon/>
                    Failed to load data from the server.
                </div>
                <div>
                    <a class="pointer" @click="loadData()"><i class="fa fa-refresh"></i> reload</a>
                </div>
            </div>
        </div>

        <inner-page-card v-if="!loading" design="basic-b" class="bs-3" :link-back="true" heading="Request Data Details">
            <div v-if="currentUser.is_superuser || currentUser.is_ventura_admin" class="row">
                <div class="col-12">
                    <section>
                        <div class="p-4">
                            <div class="row">
                                <div v-if="details.requested_by" class="col-lg-4 col-6">
                                    <h6 class="font-inter-medium fs-2">Requested By </h6>
                                    <p class="font-inter-regular fs-1">{{ details.requested_by }}</p>
                                </div>

                                <div v-if="details.requested_type" class="col-lg-4 col-6">
                                    <h6 class="font-inter-medium fs-2 ">Requested For </h6>
                                    <p v-if="details.requested_type === 1" class=" fs-1 font-inter-regular">Advisor Request</p>
                                    <p v-if="details.requested_type === 2" class=" fs-1  font-inter-regular">Datasheet Request</p>
                                </div>

                                <div v-if="details.created_at" class="col-lg-4 col-6 mt-3">
                                    <h6 class="font-inter-medium fs-2">Requested At </h6>
                                    <p class="fs-1 font-inter-regular">{{ details.created_at }}</p>
                                </div>

                                <div v-if="details.subject" class="col-lg-12 col-6 mt-lg-4 mt-0 mt-3">
                                    <h6 class="font-inter-medium fs-2">Subject </h6>
                                    <p class=" font-inter-regular fs-1">{{ details.subject }}</p>
                                </div>

                                <div v-if="details.content" class="col-12 mt-4">
                                    <h6 class="font-inter-medium fs-2">Request Content</h6>
                                    <p class= "font-inter-regular fs-1">{{ details.content }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
            <div v-else class="text-center py-7">
                <h4 class="font-inter-bold">Permission Denied!</h4>
                <p class="">Sorry, You have no permission to view this page.</p>
            </div>
        </inner-page-card>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'TransactionDetails',

    data () {
        return {
            id      : this.$route.params.id,
            details : '',
            error   : false,
            loading : true
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        loadData () {
            const that = this;
            that.loading = true;
            const model = {
                id : that.id
            };
            axios.form(urls.userList.requestDataDetails, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = { ...json.data };
                    that.loading = false;
                } else {
                    that.loading = false;
                }
            }).catch(function (exception) {
                console.log('Exception at the incident details loading => ', exception);
                that.loading = false;
            });
        }
    }
};
</script>

<style scoped>

.bg-blue-layer {
    background: url("../../assets/web/bg/blue-strip-alternate.jpg");
}

.c-margin-x {
    margin: -15px -15px 0 -15px;
}

.icon-md {
    height: 2.5rem !important;
}

</style>
